import React from 'react';

import './styles.scss'

function TextWithImage(props) {
  return (
    <div className="textWithImage-container">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-10">
            <div className={"columns" + (props.isImageRight ? '' : ' reverse-rows')}>
              <div className="column is-6">
                {props.text}
              </div>
              <div className="column is-1"></div>
              <div className="column is-5">
              <img className="about-img"
                  data-sal="slide-up"
                  data-sal-delay="50"
                  data-sal-duration="800"
                  data-sal-easing="ease"
                  src={props.image}
                  width="100%"
                  alt="text highlighting imagery"
                />
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TextWithImage;