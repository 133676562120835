import React from 'react';
import parse from 'html-react-parser';
import { Helmet } from 'react-helmet';
import Icon from "./../../../assets/right-arrow.svg";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"

import './styles.scss';
import Layout from '../../components/layout';
import TextWithImage from '../../components/TextWithImage/index.js';
import Quote from '../../components/Quote/index';
import GetInTouchCTA from '../../components/GetInTouchCTA/index';

function Work({ location, pageContext: { node } }) {
  const metaTitle = `${node.title} | Voa Labs`;

  const slugNextProject = node.nextProject.clientName.replace(/\s+/g, '-').toLowerCase();

  const options = {
    renderText: text =>
      text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: node => {
        const fields = node.data.target.fields

        switch (node.data.target.sys.contentType.sys.id) {
          case "paragraph":
            return (
              <div className="container">
                <div className="columns is-centered">
                  <div className="column is-8">
                    <p className={fields.classes !== undefined ? fields.classes["en-US"] : ''}>
                      {documentToReactComponents(fields.text["en-US"])}
                    </p>
                  </div>
                </div>
              </div>
            )

          case "layoutTextWithImage":
            return (
              <>
                <br />
                <TextWithImage
                  text={documentToReactComponents(fields.text["en-US"])}
                  image={fields.image["en-US"].fields.file["en-US"].url}
                  isImageRight={fields.isImageRight["en-US"] !== undefined ? fields.isImageRight["en-US"] : true}
                />
              </>
            )
          case "quote":

            return (
              <>
                <Quote
                  image={fields.image["en-US"].fields.file["en-US"].url}
                  quote={fields.quote["en-US"]}
                  name={fields.name["en-US"]}
                  position={fields.position["en-US"]}
                />
              </>
            )

          case "image":
            return (
              <>
                <br />
                <div className="container">
                  <div className="columns is-centered">
                    <div className={`column ${fields.class !== undefined ? fields.class["en-US"] : ''}`}>
                      <img
                        data-sal="slide-up"
                        data-sal-delay="50"
                        data-sal-duration="800"
                        data-sal-easing="ease"
                        src={fields.file["en-US"].fields.file["en-US"].url}
                        width="100%"
                        alt="project screenshot"
                      />
                    </div>

                  </div>
                </div>
              </>
            )

          default:
            return <></>
        }
      },
    },
  }

  const servicesPerformed = node.servicesPerformed.replace(/,/g, '<br>');
  const toolsUsed = node.toolsUsed.replace(/,/g, '<br>')

  return (
    <Layout location={location} showLanguageSelector={false}>
      <Helmet bodyAttributes={{ class: 'whiteHeader whiteHeader-show' }}>
        <title>{parse(metaTitle)}</title>
        <meta property="og:title" content={parse(metaTitle)} />
        <meta name="image" property="og:image" content={node.titleImage.file.url}></meta>
      </Helmet>
      <section className="section content-section portfolio-hero">
        <div className="container">
          <div className="columns">
            <div className="column is-10">
              <div className="client-company-name">{parse(node.clientName)}</div>
              <h1 className="portfolio-title">{parse(node.title)}</h1>
            </div>
            <div className="column is-3">
              <a href={node.projectLink} target="_blank" rel="noopener noreferrer"><div className="link-client"><div>View Website </div><Icon /></div></a>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="columns">
            <div className="column is-4 services-performed-wrapper">
              <div className="portfolio-specs-title">Services performed</div>
              <div className="portfolio-specs-description">
                <p dangerouslySetInnerHTML={{ __html: servicesPerformed }} />

              </div>
            </div>
            <div className="column is-4">
              <div className="portfolio-specs-title">Tools used</div>
              <div className="portfolio-specs-description">
                <p dangerouslySetInnerHTML={{ __html: toolsUsed }} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="section-featured-image-portfolio"><div style={{ backgroundImage: `url(${node.featuredImage.file.url})` }} className="featured-image-portfolio"></div>
      </div>

      <section className="section content-section portfolio-content">
        {node.content !== null &&
          documentToReactComponents(
            node.content.json,
            options
          )
        }
      </section>
      <GetInTouchCTA name={node.nextProject.clientName} link={slugNextProject} />
    </Layout >
  );
}

export default Work;