import React from 'react';
import Icon from "./../../../assets/right-arrow.svg";
import { Link } from "gatsby"

import './styles.scss'

function GetInTouchCTA (props) {
    return (
        <Link to={`/work/${props.link}`}>
        <section className="section getInTouchCTA-section">
            <div className="container ">
                <div className="columns is-centered">
                    <div className="column is-8">
                        Next Project
                        <div className="nextProject-title">{props.name}</div>
                        <Icon />
                    </div>
                </div>
            </div>
        </section>
        </Link>
    )
}

export default GetInTouchCTA;