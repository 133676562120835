import React from 'react';

import './styles.scss'

function Quote(props) {
  return (

    <div className="container quote-container">
      <div className="columns is-centered">
        <div className="column is-8">
          <div className="quote-image">
            <img src={props.image} width="64" height="64" alt="" className="quote-image" />
          </div>
          <div className="quote">
            “{props.quote}”
      </div>
          <div className="meta">
            {props.name}
          </div>
          <div className="meta-light">
            {props.position}
          </div>

        </div>
      </div>
    </div>
  )
}

export default Quote;